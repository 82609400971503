import React, { useEffect, useMemo, useRef, useState } from 'react'
import cn from 'classnames'
import styles from './TransfiProviderConnector.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAnglesRight, faArrowRight, faCircleExclamation } from '@fortawesome/free-solid-svg-icons'
import { SyncLoader } from 'react-spinners'
import { inject } from 'mobx-react'
import WidgetLayout from '@components/widget/layout/WidgetLayout'
import IframeWrapper from '@components/common/iframe-wrapper/IframeWrapper'
import providers from '@constants/providers'

const TransfiProviderConnector = (props) => {
    const { config, logger, className, onCancel, rampInfo } = props
    const { rampType, source, sourceAmount, destination, destinationAmount, withdrawalMethod, paymentMethod } = rampInfo
    const widgetLayoutRef = useRef(null)
    const iframeId = 'transfi-iframe'
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(true)
    const [isReady, setIsReady] = useState(false)
    const [, forceUpdate] = useState()

    const generateTransfiUrl = () => {
        const transfiHost = (config.app.isProduction) ? 'https://buy.transfi.com' : 'https://dev-buy.transfi.com'
        const apiKey = config.service.transfi.toUseApiKey ? config.service.transfi.apiKey : null
        const product = rampType.toLowerCase()

        let transfiUrl = `${transfiHost}/?product=${product}&cryptoTicker=${source}&cryptoAmount=${sourceAmount}&fiatTicker=${destination}&paymentCode=${withdrawalMethod}`

        if (rampType === 'BUY') {
            transfiUrl = `${transfiHost}/?product=${product}&cryptoTicker=${destination}&fiatAmount=${sourceAmount}&fiatTicker=${source}&paymentCode=${paymentMethod}`
        }

        if (apiKey) {
            transfiUrl += `&apiKey=${apiKey}`
        }

        return transfiUrl
    }

    const transfiUrl = generateTransfiUrl()

    useEffect(() => {
        forceUpdate(Math.random())
    }, [widgetLayoutRef])

    const handleIframeLoad = () => {
        setLoading(false)
        setIsReady(true)
    }

    return (
        <WidgetLayout
            ref={widgetLayoutRef}
            className={cn(styles.wrapper, className)}
            contentWrapperClassName={styles.widgetContentWrapper}
            title='Transfi'
            toShowHeader={false}
        >
            {error &&
            <div className={styles.errorWrapper}>
                <div className='flex items-center gap-3'>
                    <img
                        src='/images/logo/avenir.svg'
                        alt='Avenir'
                    />

                    <FontAwesomeIcon icon={faCircleExclamation} className='text-[20px]' color='red' />

                    <img
                        src={providers.transfi.logoUrl}
                        alt='Transfi'
                    />
                </div>

                <div className='font-semibold text-[16px] flex items-center gap-3'>
                    Unable to connect to Transfi
                </div>
            </div>}

            {!error &&
            <>
                {loading &&
                <div className={styles.loaderWrapper}>
                    <div className='flex items-center gap-3'>
                        <img
                            src='/images/logo/avenir.svg'
                            alt='Avenir'
                        />

                        <FontAwesomeIcon icon={faAnglesRight} className='text-[20px]' />

                        <img
                            src={providers.transfi.logoUrl}
                            alt='Transfi'
                        />
                    </div>

                    <div className='font-semibold text-[16px] flex items-center gap-3'>
                        Connecting you to Transfi
                        <SyncLoader
                            size={4}
                            color='#36b3d0'
                        />
                    </div>

                    <div className='flex items-center gap-3'>
                        {sourceAmount} {source}
                        <FontAwesomeIcon icon={faArrowRight} className='text-[10px]' />
                        {destinationAmount} {destination}
                    </div>
                </div>}

                <IframeWrapper
                    className={cn(styles.iframeWrapper, { [styles.show]: isReady})}
                    title='Transfi'
                    widgetLayoutRef={widgetLayoutRef}
                    cancelDrawerProps={{
                        getContainer: widgetLayoutRef.current,
                        onCancel,
                        style: {
                            position: 'absolute'
                        }
                    }}
                >
                    <iframe
                        id={iframeId}
                        src={transfiUrl}
                        onLoad={handleIframeLoad}
                    />
                </IframeWrapper>
            </>}
        </WidgetLayout>
    )
}

export default inject('config', 'logger')(TransfiProviderConnector)
