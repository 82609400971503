import countries from './countries'
import _ from 'lodash'

const currencies = {
    'JOD': {
        'name': 'Jordanian dinar',
        'symbol': 'JOD',
        'logoUrl': 'https://flagcdn.com/jo.svg'
    },
    'USD': {
        'name': 'United States dollar',
        'symbol': 'USD',
        'logoUrl': 'https://flagcdn.com/us.svg'
    },
    'RSD': {
        'name': 'Serbian dinar',
        'symbol': 'RSD',
        'logoUrl': 'https://flagcdn.com/rs.svg'
    },
    'EUR': {
        'name': 'Euro',
        'symbol': 'EUR',
        'logoUrl': 'https://flagcdn.com/eu.svg'
    },
    'BMD': {
        'name': 'Bermudian dollar',
        'symbol': 'BMD',
        'logoUrl': 'https://flagcdn.com/bm.svg'
    },
    'BOB': {
        'name': 'Bolivian boliviano',
        'symbol': 'BOB',
        'logoUrl': 'https://flagcdn.com/bo.svg'
    },
    'LYD': {
        'name': 'Libyan dinar',
        'symbol': 'LYD',
        'logoUrl': 'https://flagcdn.com/ly.svg'
    },
    'XOF': {
        'name': 'West African CFA franc',
        'symbol': 'XOF',
        'logoUrl': 'https://flagcdn.com/gw.svg'
    },
    'AMD': {
        'name': 'Armenian dram',
        'symbol': 'AMD',
        'logoUrl': 'https://flagcdn.com/am.svg'
    },
    'MUR': {
        'name': 'Mauritian rupee',
        'symbol': 'MUR',
        'logoUrl': 'https://flagcdn.com/mu.svg'
    },
    'MVR': {
        'name': 'Maldivian rufiyaa',
        'symbol': 'MVR',
        'logoUrl': 'https://flagcdn.com/mv.svg'
    },
    'MKD': {
        'name': 'denar',
        'symbol': 'MKD',
        'logoUrl': 'https://flagcdn.com/mk.svg'
    },
    'ETB': {
        'name': 'Ethiopian birr',
        'symbol': 'ETB',
        'logoUrl': 'https://flagcdn.com/et.svg'
    },
    'DKK': {
        'name': 'Danish krone',
        'symbol': 'DKK',
        'logoUrl': 'https://flagcdn.com/fo.svg'
    },
    'IQD': {
        'name': 'Iraqi dinar',
        'symbol': 'IQD',
        'logoUrl': 'https://flagcdn.com/iq.svg'
    },
    'GTQ': {
        'name': 'Guatemalan quetzal',
        'symbol': 'GTQ',
        'logoUrl': 'https://flagcdn.com/gt.svg'
    },
    'TTD': {
        'name': 'Trinidad and Tobago dollar',
        'symbol': 'TTD',
        'logoUrl': 'https://flagcdn.com/tt.svg'
    },
    'PEN': {
        'name': 'Peruvian sol',
        'symbol': 'PEN',
        'logoUrl': 'https://flagcdn.com/pe.svg'
    },
    'SRD': {
        'name': 'Surinamese dollar',
        'symbol': 'SRD',
        'logoUrl': 'https://flagcdn.com/sr.svg'
    },
    'SEK': {
        'name': 'Swedish krona',
        'symbol': 'SEK',
        'logoUrl': 'https://flagcdn.com/se.svg'
    },
    'XPF': {
        'name': 'CFP franc',
        'symbol': 'XPF',
        'logoUrl': 'https://flagcdn.com/wf.svg'
    },
    'FKP': {
        'name': 'Falkland Islands pound',
        'symbol': 'FKP',
        'logoUrl': 'https://flagcdn.com/fk.svg'
    },
    'ZWL': {
        'name': 'Zimbabwean dollar',
        'symbol': 'ZWL',
        'logoUrl': 'https://flagcdn.com/zw.svg'
    },
    'SAR': {
        'name': 'Saudi riyal',
        'symbol': 'SAR',
        'logoUrl': 'https://flagcdn.com/sa.svg'
    },
    'AED': {
        'name': 'United Arab Emirates dirham',
        'symbol': 'AED',
        'logoUrl': 'https://flagcdn.com/ae.svg'
    },
    'AFN': {
        'name': 'Afghan afghani',
        'symbol': 'AFN',
        'logoUrl': 'https://upload.wikimedia.org/wikipedia/commons/5/5c/Flag_of_the_Taliban.svg'
    },
    'XAF': {
        'name': 'Central African CFA franc',
        'symbol': 'XAF',
        'logoUrl': 'https://flagcdn.com/cm.svg'
    },
    'PAB': {
        'name': 'Panamanian balboa',
        'symbol': 'PAB',
        'logoUrl': 'https://flagcdn.com/pa.svg'
    },
    'SYP': {
        'name': 'Syrian pound',
        'symbol': 'SYP',
        'logoUrl': 'https://flagcdn.com/sy.svg'
    },
    'VUV': {
        'name': 'Vanuatu vatu',
        'symbol': 'VUV',
        'logoUrl': 'https://flagcdn.com/vu.svg'
    },
    'HNL': {
        'name': 'Honduran lempira',
        'symbol': 'HNL',
        'logoUrl': 'https://flagcdn.com/hn.svg'
    },
    'AUD': {
        'name': 'Australian dollar',
        'symbol': 'AUD',
        'logoUrl': 'https://flagcdn.com/nf.svg'
    },
    'CLP': {
        'name': 'Chilean peso',
        'symbol': 'CLP',
        'logoUrl': 'https://flagcdn.com/cl.svg'
    },
    'XCD': {
        'name': 'Eastern Caribbean dollar',
        'symbol': 'XCD',
        'logoUrl': 'https://flagcdn.com/ai.svg'
    },
    'MXN': {
        'name': 'Mexican peso',
        'symbol': 'MXN',
        'logoUrl': 'https://flagcdn.com/mx.svg'
    },
    'CNY': {
        'name': 'Chinese yuan',
        'symbol': 'CNY',
        'logoUrl': 'https://flagcdn.com/cn.svg'
    },
    'SSP': {
        'name': 'South Sudanese pound',
        'symbol': 'SSP',
        'logoUrl': 'https://flagcdn.com/ss.svg'
    },
    'SZL': {
        'name': 'Swazi lilangeni',
        'symbol': 'SZL',
        'logoUrl': 'https://flagcdn.com/sz.svg'
    },
    'UZS': {
        'name': 'Uzbekistani soʻm',
        'symbol': 'UZS',
        'logoUrl': 'https://flagcdn.com/uz.svg'
    },
    'IDR': {
        'name': 'Indonesian rupiah',
        'symbol': 'IDR',
        'logoUrl': 'https://flagcdn.com/id.svg'
    },
    'PYG': {
        'name': 'Paraguayan guaraní',
        'symbol': 'PYG',
        'logoUrl': 'https://flagcdn.com/py.svg'
    },
    'PLN': {
        'name': 'Polish złoty',
        'symbol': 'PLN',
        'logoUrl': 'https://flagcdn.com/pl.svg'
    },
    'BAM': {
        'name': 'Bosnia and Herzegovina convertible mark',
        'symbol': 'BAM',
        'logoUrl': 'https://flagcdn.com/ba.svg'
    },
    'CAD': {
        'name': 'Canadian dollar',
        'symbol': 'CAD',
        'logoUrl': 'https://flagcdn.com/ca.svg'
    },
    'BRL': {
        'name': 'Brazilian real',
        'symbol': 'BRL',
        'logoUrl': 'https://flagcdn.com/br.svg'
    },
    'MRU': {
        'name': 'Mauritanian ouguiya',
        'symbol': 'MRU',
        'logoUrl': 'https://flagcdn.com/mr.svg'
    },
    'ILS': {
        'name': 'Israeli new shekel',
        'symbol': 'ILS',
        'logoUrl': 'https://flagcdn.com/il.svg'
    },
    'ANG': {
        'name': 'Netherlands Antillean guilder',
        'symbol': 'ANG',
        'logoUrl': 'https://flagcdn.com/sx.svg'
    },
    'BND': {
        'name': 'Brunei dollar',
        'symbol': 'BND',
        'logoUrl': 'https://flagcdn.com/bn.svg'
    },
    'AOA': {
        'name': 'Angolan kwanza',
        'symbol': 'AOA',
        'logoUrl': 'https://flagcdn.com/ao.svg'
    },
    'BYN': {
        'name': 'Belarusian ruble',
        'symbol': 'BYN',
        'logoUrl': 'https://flagcdn.com/by.svg'
    },
    'TRY': {
        'name': 'Turkish lira',
        'symbol': 'TRY',
        'logoUrl': 'https://flagcdn.com/tr.svg'
    },
    'GIP': {
        'name': 'Gibraltar pound',
        'symbol': 'GIP',
        'logoUrl': 'https://flagcdn.com/gi.svg'
    },
    'BTN': {
        'name': 'Bhutanese ngultrum',
        'symbol': 'BTN',
        'logoUrl': 'https://flagcdn.com/bt.svg'
    },
    'VES': {
        'name': 'Venezuelan bolívar soberano',
        'symbol': 'VES',
        'logoUrl': 'https://flagcdn.com/ve.svg'
    },
    'QAR': {
        'name': 'Qatari riyal',
        'symbol': 'QAR',
        'logoUrl': 'https://flagcdn.com/qa.svg'
    },
    'CZK': {
        'name': 'Czech koruna',
        'symbol': 'CZK',
        'logoUrl': 'https://flagcdn.com/cz.svg'
    },
    'KWD': {
        'name': 'Kuwaiti dinar',
        'symbol': 'KWD',
        'logoUrl': 'https://flagcdn.com/kw.svg'
    },
    'INR': {
        'name': 'Indian rupee',
        'symbol': 'INR',
        'logoUrl': 'https://flagcdn.com/in.svg'
    },
    'NZD': {
        'name': 'New Zealand dollar',
        'symbol': 'NZD',
        'logoUrl': 'https://flagcdn.com/nu.svg'
    },
    'JMD': {
        'name': 'Jamaican dollar',
        'symbol': 'JMD',
        'logoUrl': 'https://flagcdn.com/jm.svg'
    },
    'KYD': {
        'name': 'Cayman Islands dollar',
        'symbol': 'KYD',
        'logoUrl': 'https://flagcdn.com/ky.svg'
    },
    'PKR': {
        'name': 'Pakistani rupee',
        'symbol': 'PKR',
        'logoUrl': 'https://flagcdn.com/pk.svg'
    },
    'KZT': {
        'name': 'Kazakhstani tenge',
        'symbol': 'KZT',
        'logoUrl': 'https://flagcdn.com/kz.svg'
    },
    'BHD': {
        'name': 'Bahraini dinar',
        'symbol': 'BHD',
        'logoUrl': 'https://flagcdn.com/bh.svg'
    },
    'FJD': {
        'name': 'Fijian dollar',
        'symbol': 'FJD',
        'logoUrl': 'https://flagcdn.com/fj.svg'
    },
    'ISK': {
        'name': 'Icelandic króna',
        'symbol': 'ISK',
        'logoUrl': 'https://flagcdn.com/is.svg'
    },
    'MMK': {
        'name': 'Burmese kyat',
        'symbol': 'MMK',
        'logoUrl': 'https://flagcdn.com/mm.svg'
    },
    'BDT': {
        'name': 'Bangladeshi taka',
        'symbol': 'BDT',
        'logoUrl': 'https://flagcdn.com/bd.svg'
    },
    'PHP': {
        'name': 'Philippine peso',
        'symbol': 'PHP',
        'logoUrl': 'https://flagcdn.com/ph.svg'
    },
    'NPR': {
        'name': 'Nepalese rupee',
        'symbol': 'NPR',
        'logoUrl': 'https://flagcdn.com/np.svg'
    },
    'YER': {
        'name': 'Yemeni rial',
        'symbol': 'YER',
        'logoUrl': 'https://flagcdn.com/ye.svg'
    },
    'KRW': {
        'name': 'South Korean won',
        'symbol': 'KRW',
        'logoUrl': 'https://flagcdn.com/kr.svg'
    },
    'OMR': {
        'name': 'Omani rial',
        'symbol': 'OMR',
        'logoUrl': 'https://flagcdn.com/om.svg'
    },
    'ERN': {
        'name': 'Eritrean nakfa',
        'symbol': 'ERN',
        'logoUrl': 'https://flagcdn.com/er.svg'
    },
    'IRR': {
        'name': 'Iranian rial',
        'symbol': 'IRR',
        'logoUrl': 'https://flagcdn.com/ir.svg'
    },
    'TZS': {
        'name': 'Tanzanian shilling',
        'symbol': 'TZS',
        'logoUrl': 'https://flagcdn.com/tz.svg'
    },
    'SBD': {
        'name': 'Solomon Islands dollar',
        'symbol': 'SBD',
        'logoUrl': 'https://flagcdn.com/sb.svg'
    },
    'KES': {
        'name': 'Kenyan shilling',
        'symbol': 'KES',
        'logoUrl': 'https://flagcdn.com/ke.svg'
    },
    'DOP': {
        'name': 'Dominican peso',
        'symbol': 'DOP',
        'logoUrl': 'https://flagcdn.com/do.svg'
    },
    'GBP': {
        'name': 'British pound',
        'symbol': 'GBP',
        'logoUrl': 'https://flagcdn.com/gb.svg'
    },
    'RWF': {
        'name': 'Rwandan franc',
        'symbol': 'RWF',
        'logoUrl': 'https://flagcdn.com/rw.svg'
    },
    'TWD': {
        'name': 'New Taiwan dollar',
        'symbol': 'TWD',
        'logoUrl': 'https://flagcdn.com/tw.svg'
    },
    'GYD': {
        'name': 'Guyanese dollar',
        'symbol': 'GYD',
        'logoUrl': 'https://flagcdn.com/gy.svg'
    },
    'SCR': {
        'name': 'Seychellois rupee',
        'symbol': 'SCR',
        'logoUrl': 'https://flagcdn.com/sc.svg'
    },
    'KPW': {
        'name': 'North Korean won',
        'symbol': 'KPW',
        'logoUrl': 'https://flagcdn.com/kp.svg'
    },
    'BWP': {
        'name': 'Botswana pula',
        'symbol': 'BWP',
        'logoUrl': 'https://flagcdn.com/bw.svg'
    },
    'KHR': {
        'name': 'Cambodian riel',
        'symbol': 'KHR',
        'logoUrl': 'https://flagcdn.com/kh.svg'
    },
    'BBD': {
        'name': 'Barbadian dollar',
        'symbol': 'BBD',
        'logoUrl': 'https://flagcdn.com/bb.svg'
    },
    'COP': {
        'name': 'Colombian peso',
        'symbol': 'COP',
        'logoUrl': 'https://flagcdn.com/co.svg'
    },
    'UAH': {
        'name': 'Ukrainian hryvnia',
        'symbol': 'UAH',
        'logoUrl': 'https://flagcdn.com/ua.svg'
    },
    'NAD': {
        'name': 'Namibian dollar',
        'symbol': 'NAD',
        'logoUrl': 'https://flagcdn.com/na.svg'
    },
    'TOP': {
        'name': 'Tongan paʻanga',
        'symbol': 'TOP',
        'logoUrl': 'https://flagcdn.com/to.svg'
    },
    'ARS': {
        'name': 'Argentine peso',
        'symbol': 'ARS',
        'logoUrl': 'https://flagcdn.com/ar.svg'
    },
    'CRC': {
        'name': 'Costa Rican colón',
        'symbol': 'CRC',
        'logoUrl': 'https://flagcdn.com/cr.svg'
    },
    'GHS': {
        'name': 'Ghanaian cedi',
        'symbol': 'GHS',
        'logoUrl': 'https://flagcdn.com/gh.svg'
    },
    'EGP': {
        'name': 'Egyptian pound',
        'symbol': 'EGP',
        'logoUrl': 'https://flagcdn.com/eg.svg'
    },
    'CUC': {
        'name': 'Cuban convertible peso',
        'symbol': 'CUC',
        'logoUrl': 'https://flagcdn.com/cu.svg'
    },
    'HUF': {
        'name': 'Hungarian forint',
        'symbol': 'HUF',
        'logoUrl': 'https://flagcdn.com/hu.svg'
    },
    'BZD': {
        'name': 'Belize dollar',
        'symbol': 'BZD',
        'logoUrl': 'https://flagcdn.com/bz.svg'
    },
    'BSD': {
        'name': 'Bahamian dollar',
        'symbol': 'BSD',
        'logoUrl': 'https://flagcdn.com/bs.svg'
    },
    'AWG': {
        'name': 'Aruban florin',
        'symbol': 'AWG',
        'logoUrl': 'https://flagcdn.com/aw.svg'
    },
    'STN': {
        'name': 'São Tomé and Príncipe dobra',
        'symbol': 'STN',
        'logoUrl': 'https://flagcdn.com/st.svg'
    },
    'RUB': {
        'name': 'Russian ruble',
        'symbol': 'RUB',
        'logoUrl': 'https://flagcdn.com/ru.svg'
    },
    'NIO': {
        'name': 'Nicaraguan córdoba',
        'symbol': 'NIO',
        'logoUrl': 'https://flagcdn.com/ni.svg'
    },
    'TND': {
        'name': 'Tunisian dinar',
        'symbol': 'TND',
        'logoUrl': 'https://flagcdn.com/tn.svg'
    },
    'CDF': {
        'name': 'Congolese franc',
        'symbol': 'CDF',
        'logoUrl': 'https://flagcdn.com/cd.svg'
    },
    'NOK': {
        'name': 'Norwegian krone',
        'symbol': 'NOK',
        'logoUrl': 'https://flagcdn.com/no.svg'
    },
    'ZAR': {
        'name': 'South African rand',
        'symbol': 'ZAR',
        'logoUrl': 'https://flagcdn.com/za.svg'
    },
    'SLL': {
        'name': 'Sierra Leonean leone',
        'symbol': 'SLL',
        'logoUrl': 'https://flagcdn.com/sl.svg'
    },
    'LSL': {
        'name': 'Lesotho loti',
        'symbol': 'LSL',
        'logoUrl': 'https://flagcdn.com/ls.svg'
    },
    'SHP': {
        'name': 'Saint Helena pound',
        'symbol': 'SHP',
        'logoUrl': 'https://flagcdn.com/gs.svg'
    },
    'DJF': {
        'name': 'Djiboutian franc',
        'symbol': 'DJF',
        'logoUrl': 'https://flagcdn.com/dj.svg'
    },
    'LKR': {
        'name': 'Sri Lankan rupee',
        'symbol': 'LKR',
        'logoUrl': 'https://flagcdn.com/lk.svg'
    },
    'WST': {
        'name': 'Samoan tālā',
        'symbol': 'WST',
        'logoUrl': 'https://flagcdn.com/ws.svg'
    },
    'TMT': {
        'name': 'Turkmenistan manat',
        'symbol': 'TMT',
        'logoUrl': 'https://flagcdn.com/tm.svg'
    },
    'MDL': {
        'name': 'Moldovan leu',
        'symbol': 'MDL',
        'logoUrl': 'https://flagcdn.com/md.svg'
    },
    'CHF': {
        'name': 'Swiss franc',
        'symbol': 'CHF',
        'logoUrl': 'https://flagcdn.com/ch.svg'
    },
    'MWK': {
        'name': 'Malawian kwacha',
        'symbol': 'MWK',
        'logoUrl': 'https://flagcdn.com/mw.svg'
    },
    'LBP': {
        'name': 'Lebanese pound',
        'symbol': 'LBP',
        'logoUrl': 'https://flagcdn.com/lb.svg'
    },
    'MNT': {
        'name': 'Mongolian tögrög',
        'symbol': 'MNT',
        'logoUrl': 'https://flagcdn.com/mn.svg'
    },
    'THB': {
        'name': 'Thai baht',
        'symbol': 'THB',
        'logoUrl': 'https://flagcdn.com/th.svg'
    },
    'NGN': {
        'name': 'Nigerian naira',
        'symbol': 'NGN',
        'logoUrl': 'https://flagcdn.com/ng.svg'
    },
    'CVE': {
        'name': 'Cape Verdean escudo',
        'symbol': 'CVE',
        'logoUrl': 'https://flagcdn.com/cv.svg'
    },
    'DZD': {
        'name': 'Algerian dinar',
        'symbol': 'DZD',
        'logoUrl': 'https://flagcdn.com/eh.svg'
    },
    'LAK': {
        'name': 'Lao kip',
        'symbol': 'LAK',
        'logoUrl': 'https://flagcdn.com/la.svg'
    },
    'AZN': {
        'name': 'Azerbaijani manat',
        'symbol': 'AZN',
        'logoUrl': 'https://flagcdn.com/az.svg'
    },
    'MAD': {
        'name': 'Moroccan dirham',
        'symbol': 'MAD',
        'logoUrl': 'https://flagcdn.com/ma.svg'
    },
    'BGN': {
        'name': 'Bulgarian lev',
        'symbol': 'BGN',
        'logoUrl': 'https://flagcdn.com/bg.svg'
    },
    'BIF': {
        'name': 'Burundian franc',
        'symbol': 'BIF',
        'logoUrl': 'https://flagcdn.com/bi.svg'
    },
    'UGX': {
        'name': 'Ugandan shilling',
        'symbol': 'UGX',
        'logoUrl': 'https://flagcdn.com/ug.svg'
    },
    'MZN': {
        'name': 'Mozambican metical',
        'symbol': 'MZN',
        'logoUrl': 'https://flagcdn.com/mz.svg'
    },
    'GEL': {
        'name': 'lari',
        'symbol': 'GEL',
        'logoUrl': 'https://flagcdn.com/ge.svg'
    },
    'RON': {
        'name': 'Romanian leu',
        'symbol': 'RON',
        'logoUrl': 'https://flagcdn.com/ro.svg'
    },
    'VND': {
        'name': 'Vietnamese đồng',
        'symbol': 'VND',
        'logoUrl': 'https://flagcdn.com/vn.svg'
    },
    'JPY': {
        'name': 'Japanese yen',
        'symbol': 'JPY',
        'logoUrl': 'https://flagcdn.com/jp.svg'
    },
    'LRD': {
        'name': 'Liberian dollar',
        'symbol': 'LRD',
        'logoUrl': 'https://flagcdn.com/lr.svg'
    },
    'HTG': {
        'name': 'Haitian gourde',
        'symbol': 'HTG',
        'logoUrl': 'https://flagcdn.com/ht.svg'
    },
    'PGK': {
        'name': 'Papua New Guinean kina',
        'symbol': 'PGK',
        'logoUrl': 'https://flagcdn.com/pg.svg'
    },
    'HKD': {
        'name': 'Hong Kong dollar',
        'symbol': 'HKD',
        'logoUrl': 'https://flagcdn.com/hk.svg'
    },
    'KGS': {
        'name': 'Kyrgyzstani som',
        'symbol': 'KGS',
        'logoUrl': 'https://flagcdn.com/kg.svg'
    },
    'MGA': {
        'name': 'Malagasy ariary',
        'symbol': 'MGA',
        'logoUrl': 'https://flagcdn.com/mg.svg'
    },
    'GMD': {
        'name': 'dalasi',
        'symbol': 'GMD',
        'logoUrl': 'https://flagcdn.com/gm.svg'
    },
    'SGD': {
        'name': 'Singapore dollar',
        'symbol': 'SGD',
        'logoUrl': 'https://flagcdn.com/sg.svg'
    },
    'MYR': {
        'name': 'Malaysian ringgit',
        'symbol': 'MYR',
        'logoUrl': 'https://flagcdn.com/my.svg'
    },
    'SOS': {
        'name': 'Somali shilling',
        'symbol': 'SOS',
        'logoUrl': 'https://flagcdn.com/so.svg'
    },
    'MOP': {
        'name': 'Macanese pataca',
        'symbol': 'MOP',
        'logoUrl': 'https://flagcdn.com/mo.svg'
    },
    'ALL': {
        'name': 'Albanian lek',
        'symbol': 'ALL',
        'logoUrl': 'https://flagcdn.com/al.svg'
    },
    'ZMW': {
        'name': 'Zambian kwacha',
        'symbol': 'ZMW',
        'logoUrl': 'https://flagcdn.com/zm.svg'
    },
    'CKD': {
        'name': 'Cook Islands dollar',
        'symbol': 'CKD',
        'logoUrl': 'https://flagcdn.com/ck.svg'
    },
    'GNF': {
        'name': 'Guinean franc',
        'symbol': 'GNF',
        'logoUrl': 'https://flagcdn.com/gn.svg'
    },
    'KMF': {
        'name': 'Comorian franc',
        'symbol': 'KMF',
        'logoUrl': 'https://flagcdn.com/km.svg'
    },
    'SDG': {
        'name': 'Sudanese pound',
        'symbol': 'SDG',
        'logoUrl': 'https://flagcdn.com/sd.svg'
    },
    'TJS': {
        'name': 'Tajikistani somoni',
        'symbol': 'TJS',
        'logoUrl': 'https://flagcdn.com/tj.svg'
    },
    'UYU': {
        'name': 'Uruguayan peso',
        'symbol': 'UYU',
        'logoUrl': 'https://flagcdn.com/uy.svg'
    }
}

// countries.forEach((country) => {
//     if (!_.isEmpty(country.currencies)) {
//         const currencyCode = Object.keys(country.currencies)[0]
//         const currency = country.currencies[currencyCode]
//         currency.symbol = currencyCode
//         currency.logoUrl = country.flags.svg

//         currencies[currencyCode] = currency
//     }
// })

// console.log(currencies)

export default currencies
