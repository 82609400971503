import React, { useState, useEffect } from 'react'
import cn from 'classnames'
import styles from './WidgetContentWrapper.module.scss'

const WidgetContentWrapper = (props) => {
    const { className } = props

    return (
        <div className={cn(styles.wrapper, className)}>
            {props.children}
        </div>
    )
}

WidgetContentWrapper.defaultProps = {
}

export default WidgetContentWrapper
