import cn from 'classnames'
import styles from './ProviderSelectOptionList.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

const ProviderSelectOptionList = (props) => {
    const { className, data, selectedIndex, handleItemClick } = props

    return (
        <ul className={cn(className, styles.wrapper)}>
            {data.map((item, index) => (
                <li
                    key={index}
                    onClick={() => handleItemClick(item, index)}
                    className={cn({ [styles.disabled]: !item.available || item.comingSoon })}
                >
                    <div className='flex flex-col gap-1 items-start'>
                        <img src={item.logoUrl} alt={item.symbol} />

                        <div className={styles.name}>
                            {item.name}
                        </div>

                        {!item.available && !item.comingSoon &&
                        <div>
                            Unavailable for selected combination
                        </div>}

                        {item.comingSoon &&
                        <div>
                            Coming Soon
                        </div>}
                    </div>

                    <div className='ml-auto flex items-center gap-2'>
                        {index === selectedIndex &&
                        <FontAwesomeIcon icon={faCheck} className='mr-2' />}

                        {/* {item.available && item.destinationAmount &&
                        <div>
                            <div className='text-gray-500'>You get</div>
                            <div className='font-semibold'>
                                {item.destinationAmount} {item.destination}
                            </div>
                        </div>} */}
                    </div>
                </li>
            ))}
        </ul>
    )
}

export default ProviderSelectOptionList
