import React, { useState, useEffect, useMemo } from 'react'
import { Provider as MobxProvider } from 'mobx-react'
import AppContext from './AppContext'
import OnlineStatusProvider from '@contexts/online-status/OnlineStatusProvider'

const { Provider } = AppContext

const AppProvider = props => {
    const services = props.container.services
    const logger = props.container.logger
    const config = props.container.config
    const webClient = props.container.webClient

    useEffect(() => {
    }, [])

    return (
        <Provider value={{}}>
            <MobxProvider
                config={config}
                services={services}
                logger={logger}
                webClient={webClient}
            >
                <OnlineStatusProvider>
                    {props.children}
                </OnlineStatusProvider>
            </MobxProvider>
        </Provider>
    )
}

export default AppProvider
