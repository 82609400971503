import { useState, useEffect } from 'react'
import { inject } from 'mobx-react'
import Layout from '@components/layout/Layout'
import SellAssetWidget from '@components/asset/sell/widget/SellAssetWidget'
import TransfiProviderConnector from '@components/provider/connector/transfi/TransfiProviderConnector'
import RampProviderConnector from '@components/provider/connector/ramp/RampProviderConnector'
import TransakProviderConnector from '@components/provider/connector/transak/TransakProviderConnector'
import BuyAssetWidget from '@components/asset/buy/widget/BuyAssetWidget'
import { useSearchParams } from 'react-router-dom'

const HomePage = (props) => {
    const { services, logger } = props
    const [searchParams] = useSearchParams()
    const rampTypes = ['SELL', 'BUY']
    const defaultRampType = 'SELL'
    const [rampType, setRampType] = useState(searchParams.get('type') || defaultRampType)
    const [toConnectProvider, setToConnectProvider] = useState(false)
    const [rampInfo, setRampInfo] = useState(null)

    useEffect(() => {
        if (searchParams.get('type') && rampTypes.includes(searchParams.get('type'))) {
            setRampType(searchParams.get('type'))
        } else {
            setRampType(defaultRampType)
        }
    }, [searchParams.get('type')])

    const handleSuccessSubmit = (data) => {
        setToConnectProvider(true)
        setRampInfo({
            ...data,
            rampType
        })
    }

    const handleProviderConnectorCancel = () => {
        setToConnectProvider(false)
    }

    return (
        <Layout toShowBackgroundImage>
            {!toConnectProvider &&
            <>
                {rampType === 'SELL' &&
                <SellAssetWidget
                    formProps={{
                        defaultValues: {
                            sourceAmount: 500,
                            source: 'USDC',
                            destinationAmount: 0,
                            destination: 'EUR'
                        },
                        onSuccessSubmit: handleSuccessSubmit
                    }}
                />}

                {rampType === 'BUY' &&
                <BuyAssetWidget
                    formProps={{
                        defaultValues: {
                            sourceAmount: 500,
                            source: 'USD',
                            destinationAmount: 0,
                            destination: 'USDC'
                        },
                        onSuccessSubmit: handleSuccessSubmit
                    }}
                />}
            </>}

            {toConnectProvider &&
            <>
                {rampInfo.provider === 'transfi' &&
                <TransfiProviderConnector
                    rampInfo={rampInfo}
                    onCancel={handleProviderConnectorCancel}
                />}

                {rampInfo.provider === 'ramp' &&
                <RampProviderConnector
                    rampInfo={rampInfo}
                    onCancel={handleProviderConnectorCancel}
                />}

                {rampInfo.provider === 'transak' &&
                <TransakProviderConnector
                    rampInfo={rampInfo}
                    onCancel={handleProviderConnectorCancel}
                />}
            </>}
        </Layout>
    )
}

export default inject('services', 'logger')(HomePage)
