import React, { useState, useEffect, ef } from 'react'
import cn from 'classnames'
import Header from './header/Header'
import ContentWrapper from './content-wrapper/ContentWrapper'
import styles from './Layout.module.scss'

const Layout = (props) => {
    const { toShowBackgroundImage } = props

    return (
        <div className={styles.wrapper}>
            <Header />

            <ContentWrapper toShowBackgroundImage={toShowBackgroundImage} >
                {props.children}
            </ContentWrapper>
        </div>
    )
}

Layout.defaultProps = {
    showHeader: true,
    toShowBackgroundImage: false
}

export default Layout
