import Modal from '@components/common/modal/Modal'

const showApiErrorMessage = (errors, onOk = null) => {
    const options = {
        title: 'Error',
        content: (
            <ul>
                {Object.values(errors).map((messages, index) => {
                    return (
                        messages.map((message, index) =>
                            <li key={index}>{message}</li>
                        )
                    )
                })}
            </ul>
        )
    }
    if (onOk) options.onOk = onOk
    Modal.error(options)
}

export default showApiErrorMessage
