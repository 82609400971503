import cn from 'classnames'
import styles from './BuyAssetSummary.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRightArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { HashLoader } from 'react-spinners'
import ProviderSelectInput from '@components/provider/input/select/ProviderSelectInput'
import { useRef } from 'react'

const BuyAssetSummary = (props) => {
    const {
        className, widgetLayoutRef, loading,
        summary, hasError,
        selectProviderInputProps
    } = props

    const selectInputRef = useRef(null)

    const {
        source, sourceAmount, destination, destinationAmount
    } = summary

    return (
        <div className={cn(styles.wrapper, className, { [styles.error]: hasError })}>
            {!loading && 
            <>
                <div className={styles.exchangeRate}>
                    {sourceAmount} {source} <FontAwesomeIcon icon={faArrowRightArrowLeft} className='mx-2' /> {destinationAmount} {destination}
                </div>

                <ProviderSelectInput
                    ref={selectInputRef}
                    onClick={(e) => e?.stopPropagation()}
                    widgetLayoutRef={widgetLayoutRef}
                    optionTitle={selectProviderInputProps?.optionTitle}
                    options={selectProviderInputProps?.options}
                    placeholder={selectProviderInputProps?.placeholder}
                    value={selectProviderInputProps?.value}
                    onChange={selectProviderInputProps.onChange}
                />
            </>}

            {loading &&
            <div className='flex gap-3 items-center'>
                <HashLoader
                    size={15}
                    color='#36b3d0'
                />
                Fetching best price ...
            </div>}
        </div>
    )
}

export default BuyAssetSummary
