import React, { useState, useEffect } from 'react'
import cn from 'classnames'
import HashLoader from 'react-spinners/HashLoader'
import styles from './SubmitButton.module.scss'

const SubmitButton = props => {
    return (
        <button
            type='submit'
            className={cn(props.className, styles.wrapper)}
            disabled={props.submitting || props.disabled}
            style={props.style}
        >
            {!props.submitting &&
            <span>{props.label}</span>}

            {props.submitting &&
            <div className='flex justify-center'>
                <HashLoader
                    size={20}
                    color='white'
                    loading={props.submitting}
                />
            </div>}
        </button>
    )
}

SubmitButton.defaultProps = {
    submitting: false,
    disabled: false,
    labelSubmitting: 'Submitting'
}

export default SubmitButton
