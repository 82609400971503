import { number, string, object } from 'yup'

const schema = object().shape({
    source: string()
        .nullable()
        .required('Source is a required field'),
    sourceAmount: number()
        .nullable()
        .transform((value, orginalValue) => {
            return (isNaN(value)) ? null : value
        })
        .min(1, 'Source amount must be greater than 0')
        .required('Source amount is a required field'),
    destination: string()
        .nullable()
        .required('Destionation is a required field'),
    provider: string()
        .nullable()
        .required('Provider is a required field'),
    paymentMethod: string()
        .nullable()
        .required('Payment method is a required field')
})

export default schema
