import React, { useState, useEffect, useMemo, useContext } from 'react'
import { useForm, Controller, set } from 'react-hook-form'
import cn from 'classnames'
import { Form } from 'antd'
import { yupResolver } from '@hookform/resolvers/yup'
import { inject, observer } from 'mobx-react'
import { useDebouncedCallback } from 'use-debounce'
import _ from 'lodash'
import schema from './schema'
import styles from './SellAssetForm.module.scss'
import SubmitButton from '@components/common/button/submit/SubmitButton'
import AssetInput from '@components/asset/input/AssetInput'
import SellAssetSummary from '../summary/SellAssetSummary'
import WithdrawalMethodSelectInput from '@components/withdrawal-method/input/select/WithdrawalMethodSelectInput'
import SellAtssetFormPlaceholder from './placeholder/SellAssetFormPlaceholder'
import currencies from '@constants/currencies'
import Modal from '@components/common/modal/Modal'
import showApiErrorMessage from '@helpers/message/error/showApiErrorMessage'
import { useSearchParams } from 'react-router-dom'

const SellAssetForm = props => {
    const { logger, services, widgetLayoutRef, defaultValues, selectedCryptoAsset, selectedFiatCurrency, onSuccessSubmit } = props
    const { offrampService } = services
    const [form] = Form.useForm()
    const { setError, setFocus, trigger, setValue, handleSubmit, watch, control, formState: { errors } } = useForm({
        mode: 'onChange',
        resolver: yupResolver(schema),
        defaultValues
    })

    const [validating, setValidating] = useState(true)
    const [loading, setLoading] = useState(true)
    const [cryptoOptions, setCryptoOptions] = useState([])
    const [fiatCurrencyOptions, setFiatCurrencyOptions] = useState([])
    const [loadingBestExchangeRate, setLoadingBestExchangeRate] = useState(true)
    const [withdrawalMethodOptions, setWithdrawalMethodOptions] = useState([])
    const [submitting, setSubmitting] = useState(false)
    const [providerOptions, setProviderOptions] = useState([])
    const [providers, setProviders] = useState(null)
    const [cryptos, setCryptos] = useState(null)
    const [searchParams] = useSearchParams()
    const sourceQueryParameter = searchParams.get('source')
    const destinationQueryParameter = searchParams.get('destination')
    const sourceAmountQueryParameter = searchParams.get('sourceAmount')

    const source = watch('source')
    const sourceAmount = watch('sourceAmount')
    const network = watch('network')
    const destination = watch('destination')
    const destinationAmount = watch('destinationAmount')
    const provider = watch('provider')
    const withdrawalMethod = watch('withdrawalMethod')
    const selectedCrypto = cryptos?.[source]

    useEffect(() => {
        validate(sourceQueryParameter, destinationQueryParameter, sourceAmountQueryParameter)
    }, [sourceQueryParameter, destinationQueryParameter, sourceAmountQueryParameter])

    useEffect(() => {
        const load = async () => {
            try {
                setLoading(true)
                await new Promise(resolve => setTimeout(resolve, 1000))
                await Promise.all([
                    getCryptoOptions(),
                    getFiatCurrencyOptions()
                ])
            } catch (err) {
                logger.error('[Sell Asset Form Load Error]', err)
            } finally {
                setLoading(false)
            }
        }
        load()
    }, [])

    useEffect(() => {
        if (!validating && !loading && source && sourceAmount && destination) {
            setLoadingBestExchangeRate(true)
            debounceGetBestExchangeRate()
        }
    }, [validating, loading, source, sourceAmount, destination])

    useEffect(() => {
        if (destination) {
            setValue('withdrawalMethod', null)
        }
    }, [destination])

    useEffect(() => {
        if (!loading && provider) {
            getProviderOptions()
        }
    }, [loading, provider])

    useEffect(() => {
        if (withdrawalMethodOptions.length > 0) {
            setValue('withdrawalMethod', withdrawalMethodOptions[0].value)
        }
    }, [withdrawalMethodOptions])

    useEffect(() => {
        if (selectedCrypto && provider) {
            const sourceDecimals = selectedCrypto.partners[provider]?.decimals
            const network = selectedCrypto.partners[provider]?.network
            setValue('sourceDecimals', sourceDecimals)
            setValue('network', network)
        }
    }, [selectedCrypto, provider])

    const reload = () => {
        window.location.href = '/#/?type=SELL'
        window.location.reload()
    }

    const validate = async (source, destination, sourceAmount) => {
        try {
            setValidating(true)
            const res = await offrampService.validate({
                source, destination, sourceAmount
            })

            if (res.ok) {
                const data = res.data

                if (data.isValid) {
                    if (source) setValue('source', source)
                    if (destination) setValue('destination', destination)
                    if (sourceAmount) setValue('sourceAmount', sourceAmount)
                } else {
                    reload()
                }
            } else {
                if (res.status === 400) {
                    reload()
                }
            }
        } catch (error) {
            logger.error('[Validate Error]', error)
        } finally {
            setValidating(false)
        }
    }

    const getCryptoOptions = async () => {
        try {
            const res = await offrampService.getSupportedCryptos()

            if (res.ok) {
                const data = res.data
                setCryptos(data.cryptos)

                const cryptoOptions = []
                for (const [key, value] of Object.entries(data.cryptos)) {
                    cryptoOptions.push({
                        name: value.symbol,
                        desc: value.name,
                        logoUrl: `/images/crypto/logo/${value.symbol?.toLowerCase()}.svg`,
                        value: value.symbol
                    })
                }
                setCryptoOptions(cryptoOptions)
            }
        } catch (error) {
            logger.error('[Get Crypto Error]', error)
        }
    }

    const getFiatCurrencyOptions = async () => {
        try {
            const res = await offrampService.getSupportedFiatCurrencies()

            if (res.ok) {
                const data = res.data

                const currencyOptions = []
                for (const [key, value] of Object.entries(data.currencies)) {
                    const currency = currencies[key]
                    currencyOptions.push({
                        name: currency.symbol,
                        desc: currency.name,
                        logoUrl: currency.logoUrl,
                        value: currency.symbol
                    })
                }
                setFiatCurrencyOptions(currencyOptions)
                setProviders(data.partners)
            }
        } catch (error) {
            logger.error('[Get Fiat Currency Error]', error)
        }
    }

    const getBestExchangeRate = async () => {
        try {
            setLoadingBestExchangeRate(true)
            const res = await offrampService.getBestExchangeRate({
                source,
                sourceAmount,
                destination,
                network
            })

            if (res.ok) {
                const data = res.data.data
                setValue('provider', data.partner)
                getProviderOptions()
                const destinationAmount = Math.floor(data.receiveAmount * 100) / 100
                setValue('destinationAmount', destinationAmount)

                await getWithdrawalMethodOptions(data.partner)
            } else if (res.problem === 'CLIENT_ERROR') {
                const errors = res.data?.errors
                if (errors) {
                    showApiErrorMessage(errors, () => {
                        reload()
                    })
                } else {
                    Modal.error({
                        title: 'Error',
                        content: (
                            <p>Something went wrong</p>
                        ),
                        onOk: () => {
                            reload()
                        }
                    })
                }
            }
        } catch (error) {
            logger.error('[Get Best Exchange Rate Error]', error)
        } finally {
            setLoadingBestExchangeRate(false)
        }
    }

    const getProviderOptions = async () => {
        const providerOptions = []
        for (const [key, value] of Object.entries(providers)) {
            const provider = value
            const available = provider.currencies?.includes(destination) || false

            providerOptions.push({
                id: provider.id,
                name: provider.name,
                logoUrl: `/images/provider/logo/${provider.id}.png`,
                value: provider.id,
                comingSoon: provider.comingSoon,
                currencies: provider.currencies,
                available
            })
        }
        setProviderOptions(providerOptions)
    }

    const debounceGetBestExchangeRate = useDebouncedCallback(getBestExchangeRate, 500)

    const getWithdrawalMethodOptions = async (provider) => {
        try {
            const res = await offrampService.getProviderWithdrawalMethods(provider)

            if (res.ok) {
                const data = res.data
                const options = []
                data.forEach((method) => {
                    const isAvailable = method.currencies.includes(destination)

                    if (isAvailable) {
                        options.push({
                            name: method.name,
                            logo: method.logo,
                            value: method.paymentCode
                        })
                    }
                })
                setWithdrawalMethodOptions(options)
            }
        } catch (e) {
            logger.error('[Get Withdrawal Method Error]', e)
        }
    }

    const getQuote = async (provider) => {
        try {
            setLoadingBestExchangeRate(true)
            const network = selectedCrypto.partners[provider]?.network

            const res = await offrampService.getProviderQuoteDetail(provider, {
                source,
                sourceAmount,
                destination,
                network
            })

            if (res.ok) {
                const data = res.data
                const destinationAmount = data.receiveAmount
                setValue('destinationAmount', destinationAmount)
                await getWithdrawalMethodOptions(data.partner)
            } else if (res.problem === 'CLIENT_ERROR') {
                const errors = res.data?.errors
                if (errors) {
                    showApiErrorMessage(errors, () => {
                        window.location.reload()
                    })
                } else {
                    Modal.error({
                        title: 'Error',
                        content: (
                            <p>Something went wrong</p>
                        ),
                        onOk: () => {
                            window.location.reload()
                        }
                    })
                }
            }
        } catch (error) {
            logger.error('[Get Quote Error]', error)
        } finally {
            setLoadingBestExchangeRate(false)
        }
    }

    const onSubmit = async (data) => {
        try {
            logger.info('[Sell Asset Data]', data)
            onSuccessSubmit(data)
            setSubmitting(true)
        } catch (error) {
            logger.error('[Failed Submit Sell Asset Form]', error)
        } finally {
            setSubmitting(false)
        }
    }

    if (loading) {
        return (
            <SellAtssetFormPlaceholder />
        )
    }

    return (
        <Form
            className={cn(props.className, styles.wrapper)}
            style={props.style}
            layout='vertical'
            onFinish={handleSubmit(onSubmit)}
            autoComplete='off'
            form={form}
        >
            <Form.Item
                className='mb-4'
                validateStatus={(errors?.source?.message || errors?.sourceAmount?.message) ? 'error' : '-'}
                help={errors?.source?.message || errors?.sourceAmount?.message}
            >
                <AssetInput
                    label='You Sell'
                    hasError={errors?.source?.message || errors?.sourceAmount?.message}
                    widgetLayoutRef={widgetLayoutRef}
                    amountInputProps={{
                        placeholder: '0.00',
                        value: sourceAmount,
                        onChange: (value) => {
                            setValue('sourceAmount', value)
                            trigger('sourceAmount')
                        }
                    }}
                    selectInputProps={{
                        optionTitle: 'Select Crypto',
                        toAsyncLoad: false,
                        toReturnOptionAsValue: true,
                        options: cryptoOptions,
                        placeholder: 'Select Crypto',
                        value: source,
                        onChange: (data) => {
                            setValue('source', data.value)
                            trigger('source')
                            setValue('withdrawalMethod', null)
                        }
                    }}
                />
            </Form.Item>

            <Form.Item
                className='mb-4'
                validateStatus={(errors?.destination?.message || errors?.destinationAmount?.message) ? 'error' : '-'}
                help={errors?.destination?.message || errors?.destinationAmount?.message}
            >
                <AssetInput
                    label='You Receive (Estimated)'
                    hasError={errors?.destination?.message || errors?.destinationAmount?.message}
                    widgetLayoutRef={widgetLayoutRef}
                    amountInputProps={{
                        placeholder: '0.00',
                        value: destinationAmount,
                        disabled: true
                    }}
                    selectInputProps={{
                        optionTitle: 'Select Fiat Currency',
                        toAsyncLoad: false,
                        options: fiatCurrencyOptions,
                        placeholder: 'Select Currency',
                        value: destination,
                        onChange: (value) => {
                            setValue('destination', value)
                            trigger('destination')
                            setValue('withdrawalMethod', null)
                        }
                    }}
                />
            </Form.Item>

            <Form.Item
                className='mb-4'
                validateStatus={(errors?.provider?.message) ? 'error' : '-'}
                help={errors?.provider?.message}
            >
                <SellAssetSummary
                    hasError={errors?.provider?.message}
                    widgetLayoutRef={widgetLayoutRef}
                    loading={loadingBestExchangeRate}
                    selectProviderInputProps={{
                        optionTitle: 'Select Provider',
                        options: providerOptions,
                        placeholder: 'Select Provider',
                        value: provider,
                        onChange: (value) => {
                            setValue('provider', value)
                            trigger('provider')
                            getQuote(value)
                        }
                    }}
                    summary={{
                        source,
                        sourceAmount,
                        destination,
                        destinationAmount,
                        provider
                    }}
                />
            </Form.Item>

            <Form.Item
                label='Withdrawal Method'
                className='mb-4'
                validateStatus={(errors?.withdrawalMethod?.message) ? 'error' : '-'}
                help={errors?.withdrawalMethod?.message}
            >
                <WithdrawalMethodSelectInput
                    disabled={loadingBestExchangeRate || !provider}
                    hasError={errors?.withdrawalMethod?.message}
                    widgetLayoutRef={widgetLayoutRef}
                    optionTitle='Select a Withdrawal Method'
                    options={withdrawalMethodOptions}
                    placeholder='Select withdrawal method'
                    value={withdrawalMethod}
                    onChange={(value) => {
                        setValue('withdrawalMethod', value)
                        trigger('withdrawalMethod')
                    }}
                />
            </Form.Item>

            <SubmitButton
                label={`Sell ${source}`}
                disabled={loadingBestExchangeRate || submitting}
                className='w-full mt-auto'
                submitting={submitting}
            />
        </Form>
    )
}

SellAssetForm.defaultProps = {
    onSuccessSubmit: () => {},
    defaultValues: {
        symbol: 'USDC'
    }
}

export default inject('services', 'logger')(observer(SellAssetForm))
