const providers = {
    transfi: {
        id: 'transfi',
        name: 'Transfi',
        logoUrl: '/images/provider/logo/transfi.png'
    },
    ramp: {
        id: 'ramp',
        name: 'Ramp',
        logoUrl: '/images/provider/logo/ramp.png'
    },
    transak: {
        id: 'transak',
        name: 'Transak',
        logoUrl: '/images/provider/logo/transak.png'
    }
}

export default providers
