import React, { useEffect, useMemo, useRef, useState } from 'react'
import cn from 'classnames'
import styles from './RampProviderConnector.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAnglesRight, faArrowRight, faCircleExclamation } from '@fortawesome/free-solid-svg-icons'
import { SyncLoader } from 'react-spinners'
import { RampInstantSDK } from '@ramp-network/ramp-instant-sdk'
import { inject } from 'mobx-react'
import WidgetLayout from '@components/widget/layout/WidgetLayout'
import IframeWrapper from '@components/common/iframe-wrapper/IframeWrapper'
import providers from '@constants/providers'

const RampProviderConnector = (props) => {
    const { config, logger, className, onCancel, rampInfo } = props
    const {
        rampType, source, sourceAmount, network, sourceDecimals,
        destination, destinationAmount, destinationDecimals,
        withdrawalMethod, paymentMethod
    } = rampInfo
    const rampContainerId = 'ramp-container'
    const widgetLayoutRef = useRef(null)
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(!config.service.ramp.comingSoon)
    const [, forceUpdate] = useState()

    const handleRampEvent = (event) => {
        logger.info('[Ramp Instant SDK Event]', event)
        if (event.type === 'WIDGET_CONFIG_DONE') {
            delayHideLoader()
        } else if (event.type === 'WIDGET_CONFIG_FAILED') {
            delayHideLoader()
        } else if (event.type === 'WIDGET_CLOSE') {
            onCancel()
        }
    }

    useEffect(() => {
        if (!config.service.ramp.comingSoon) {
            load()
        } else {
            setLoading(false)
            setError(null)
        }
    }, [])

    useEffect(() => {
        forceUpdate(Math.random())
    }, [widgetLayoutRef])

    const delayHideLoader = async () => {
        // Delay to prevent flickering
        await new Promise((resolve) => setTimeout(resolve, 1000))
        setLoading(false)
    }

    const generateRampConfig = () => {
        const rampConfig = {
            hostAppName: 'Avenir',
            hostLogoUrl: 'https://i.ibb.co/zRkrcgQ/Rectangle-48.png',
            variant: 'embedded-mobile',
            containerNode: document.getElementById(rampContainerId)
        }

        if (!config.app.isProduction) {
            rampConfig.url = 'https://app.demo.ramp.network'
        }

        if (rampType === 'SELL') {
            rampConfig.enabledFlows = ['OFFRAMP']
            rampConfig.defaultFlow = ['OFFRAMP']
            rampConfig.offrampAsset = `${network}_${source}`
            rampConfig.swapAsset = `${network}_${source}`
            rampConfig.swapAmount = sourceAmount * Math.pow(10, sourceDecimals)
            rampConfig.fiatCurrency = destination
        } else {
            rampConfig.enabledFlows = ['ONRAMP']
            rampConfig.defaultFlow = ['ONRAMP']
            rampConfig.offrampAsset = `${network}_${destination}`
            rampConfig.swapAsset = `${network}_${destination}`
            rampConfig.fiatCurrency = source
            rampConfig.fiatValue = sourceAmount
        }

        return rampConfig
    }


    const load = async () => {
        try {
            setLoading(true)
            const rampConfig = generateRampConfig()

            if (config.service.ramp.toUseApiKey) {
                rampConfig.hostApiKey = config.service.ramp.hostApiKey
            }

            return new RampInstantSDK(rampConfig)
                .on('*', handleRampEvent).show()
        } catch (e) {
            logger.error('[Load Ramp Instant SDK Error]', e)
            delayHideLoader()
            setError(true)
        }
    }

    return (
        <WidgetLayout
            ref={widgetLayoutRef}
            className={styles.wrapper}
            contentWrapperClassName={styles.widgetContentWrapper}
            title='Transfi'
            toShowHeader={false}
        >
            {error &&
            <div className={styles.errorWrapper}>
                <div className='flex items-center gap-3'>
                    <img
                        src='/images/logo/avenir.svg'
                        alt='Avenir'
                    />

                    <FontAwesomeIcon icon={faCircleExclamation} className='text-[20px]' color='red' />

                    <img
                        src={providers.ramp.logoUrl}
                        alt='Ramp'
                    />
                </div>

                <div className='font-semibold text-[16px] flex items-center gap-3'>
                    Unable to connect to Ramp
                </div>
            </div>}

            {!error &&
            <>
                {loading &&
                <div className={styles.loaderWrapper}>
                    <div className='flex items-center gap-3'>
                        <img
                            src='/images/logo/avenir.svg'
                            alt='Avenir'
                        />

                        <FontAwesomeIcon icon={faAnglesRight} className='text-[20px]' />

                        <img
                            src={providers.ramp.logoUrl}
                            alt='Ramp'
                        />
                    </div>

                    <div className='font-semibold text-[16px] flex items-center gap-3'>
                        Connecting you to Ramp
                        <SyncLoader
                            size={4}
                            color='#36b3d0'
                        />
                    </div>

                    <div className='flex items-center gap-3'>
                        {sourceAmount} {source}
                        <FontAwesomeIcon icon={faArrowRight} className='text-[10px]' />
                        {destinationAmount} {destination}
                    </div>
                </div>}
            </>}

            <IframeWrapper
                className={cn(styles.iframeWrapper, { [styles.show]: !loading && !error })}
                title='Ramp'
                widgetLayoutRef={widgetLayoutRef}
                cancelDrawerProps={{
                    getContainer: widgetLayoutRef.current,
                    onCancel,
                    style: {
                        position: 'absolute'
                    }
                }}
            >
                {config.service.ramp.comingSoon &&
                <div className={styles.comingSoonWrapper}>
                    <div className={styles.title}>
                        <img
                            src={providers.ramp.logoUrl}
                            alt='Ramp'
                        />
                        Ramp Integration Coming Soon ...
                    </div>
                    <div>
                        We&apos;ll soon be integrating Ramp Network into our platform for enhanced functionality and seamless transactions
                    </div>
                </div>}

                {!config.service.ramp.comingSoon &&
                <div id={rampContainerId} className={cn(styles.rampContainer, { [styles.show]: !loading && !error })} />}
            </IframeWrapper>
        </WidgetLayout>
    )
}

export default inject('config', 'logger')(RampProviderConnector)
