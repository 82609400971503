import React, { useState, useEffect } from 'react'
import cn from 'classnames'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import styles from './HamburgerButton.module.scss'

const HamburgerBarsButton = (props) => {
    return (
        <button
            type='button'
            onClick={props.onClick}
            style={props.style}
            className={cn(props.className, styles.button)}
        >
            <FontAwesomeIcon icon={faBars} size='sm' />
        </button>
    )
}

HamburgerBarsButton.propTypes = {
    style: PropTypes.object,
    className: PropTypes.string,
    onClick: PropTypes.func
}

HamburgerBarsButton.defaultProps = {
}

export default HamburgerBarsButton
