import cn from 'classnames'
import WidgetLayout from '@components/widget/layout/WidgetLayout'
import styles from './BuyAssetWidget.module.scss'
import BuyAssetForm from '../form/BuyAssetForm'
import { useRef, useState } from 'react'

const BuyAssetWidget = (props) => {
    const { className, formProps } = props
    const widgetLayoutRef = useRef(null)

    return (
        <WidgetLayout
            ref={widgetLayoutRef}
            className={cn(className, styles.wrapper)}
            title='Buy Crypto'
        >
            <BuyAssetForm
                widgetLayoutRef={widgetLayoutRef}
                defaultValues={formProps.defaultValues}
                onSuccessSubmit={formProps.onSuccessSubmit}
            />
        </WidgetLayout>
    )
}

export default BuyAssetWidget
