import React, { useState, useEffect } from 'react'
import cn from 'classnames'
import styles from './WidgetHeader.module.scss'
import LogoButton from '@components/common/button/logo/LogoButton'
import HamburgerBarsButton from '@components/common/button/hamburger/HamburgerButton'

const WidgetHeader = (props) => {
    const { className, title, toggleMenuButtonOnClick } = props

    useEffect(() => {
    }, [])

    return (
        <header className={cn(className, styles.wrapper)} style={props.style}>
            <LogoButton toShowAppName={false} />

            <div className={styles.title}>
                {title}
            </div>

            <HamburgerBarsButton onClick={toggleMenuButtonOnClick} />
        </header>
    )
}

WidgetHeader.defaultProps = {
    toggleMenuButtonOnClick: () => {}
}

export default WidgetHeader
