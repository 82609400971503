import React, { useState, useEffect } from 'react'
import cn from 'classnames'
import styles from './IframeWrapper.module.scss'
import LogoButton from '@components/common/button/logo/LogoButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock, faTimes } from '@fortawesome/free-solid-svg-icons'
import Drawer from '../drawer/Drawer'

const IframeWrapper = React.forwardRef((props, ref) => {
    const { className, title, toShowHeader, children, cancelDrawerProps } = props
    const [toShowCancelDrawer, setToShowCancelDrawer] = useState(false)

    useEffect(() => {
    }, [cancelDrawerProps])

    return (
        <div ref={ref} className={cn(styles.wrapper, className)}>
            {toShowHeader &&
            <div className={styles.header} style={props.style}>
                <LogoButton className={styles.logo} toShowAppName={false} />

                <div className={styles.title}>
                    <FontAwesomeIcon icon={faLock} className='text-[10px]' /> {title}
                </div>

                <button type='button' onClick={() => setToShowCancelDrawer(true)}>
                    <FontAwesomeIcon icon={faTimes} />
                </button>
            </div>}

            {children}

            <Drawer
                className={styles.drawer}
                open={toShowCancelDrawer}
                onClose={() => setToShowCancelDrawer(false)}
                getContainer={cancelDrawerProps?.getContainer}
                style={cancelDrawerProps?.style}
                footer={null}
                height={180}
                placement='bottom'
                onClick={(e) => e.stopPropagation()}
            >
                <div className={styles.body}>
                    <div className={styles.title}>
                        Are you sure you want to cancel?
                    </div>

                    <div className={styles.desc}>
                        Any progress will be lost
                    </div>

                    <div className={styles.btnContainer}>
                        <button type='button' className={styles.btnDismiss} onClick={() => setToShowCancelDrawer(false)}>
                            Dismiss
                        </button>

                        <button
                            type='button'
                            className={styles.btnCancel}
                            onClick={() => {
                                setToShowCancelDrawer(false)
                                cancelDrawerProps?.onCancel()
                            }}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </Drawer>
        </div>
    )
})

IframeWrapper.defaultProps = {
    toShowHeader: true
}

export default IframeWrapper
