const serviceConfig = {
    transfi: {
        toUseApiKey: process.env.REACT_APP_TRANSFI_TO_USE_API_KEY === 'true' || false,
        apiKey: process.env.REACT_APP_TRANSFI_API_KEY,
        comingSoon: process.env.REACT_APP_TRANSFI_COMING_SOON === 'true' || false
    },
    ramp: {
        toUseApiKey: process.env.REACT_APP_RAMP_TO_USE_API_KEY === 'true' || false,
        hostApiKey: process.env.REACT_APP_RAMP_HOST_API_KEY,
        comingSoon: process.env.REACT_APP_RAMP_COMING_SOON === 'true' || false
    },
    transak: {
        toUseApiKey: process.env.REACT_APP_TRANSAK_TO_USE_API_KEY === 'true' || false,
        apiKey: process.env.REACT_APP_TRANSAK_API_KEY,
        comingSoon: process.env.REACT_APP_TRANSAK_COMING_SOON === 'true' || false
    }
}

export default serviceConfig
