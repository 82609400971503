import cn from 'classnames'
import ContentLoader from 'react-content-loader'

const SellAtssetFormPlaceholder = (props) => {
    const { className } = props
    return (
        <div className={cn(className, 'flex flex-col h-full')}>
            <ContentLoader 
                speed={2}
                width='100%'
                height={95}
            >
                <rect rx='2' ry='2' width='100%' height='95' />
            </ContentLoader>

            <ContentLoader
                className='mt-3'
                speed={2}
                width='100%'
                height={95}
            >
                <rect rx='2' ry='2' width='100%' height='95' />
            </ContentLoader>

            <ContentLoader
                className='mt-3'
                speed={2}
                width='100%'
                height={50}
            >
                <rect rx='2' ry='2' width='100%' height='50' />
            </ContentLoader>

            <div className='mt-3 font-semibold'>
                Payment Method
            </div>

            <ContentLoader
                className='mt-3'
                speed={2}
                width='100%'
                height={50}
            >
                <rect rx='2' ry='2' width='100%' height='50' />
            </ContentLoader>

            <ContentLoader
                className='mt-auto'
                speed={2}
                width='100%'
                height={50}
            >
                <rect rx='2' ry='2' width='100%' height='50' />
            </ContentLoader>
        </div>
    )
}

export default SellAtssetFormPlaceholder