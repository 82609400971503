import React, { useEffect, useState } from 'react'
import cn from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import styles from './WithdrawalMethodSelectInput.module.scss'
import WithdrawalMethodOptionDrawer from './option/drawer/WithdrawalMethodOptionDrawer'
import { inject } from 'mobx-react'

const WithdrawalMethodSelectInput = React.forwardRef((props, ref) => {
    const { logger, disabled, className, widgetLayoutRef, optionTitle, options, placeholder, value, onChange, hasError } = props
    const [toShowDrawer, setToShowDrawer] = useState(false)
    const [selectedIndex, setSelectedIndex] = useState(-1)
    const [selectedOption, setSelectedOption] = useState(value)

    useEffect(() => {
    }, [])

    useEffect(() => {
        if (value) {
            const index = options.findIndex(option => option.value === value)
            setSelectedIndex(index)
            if (value && options.length === 0) {
                setSelectedOption(value)
            } else {
                setSelectedOption(options[index])
            }
        } else {
            setSelectedIndex(-1)
            setSelectedOption(null)
        }
    }, [options, value])

    const handleClick = (e) => {
        if (disabled) return
        setToShowDrawer(true)
    }

    const renderLogo = (item) => {
        const isUrl = item.logo && item.logo.startsWith('http')
        if (isUrl) {
            return (
                <img className={styles.logo} src={item.logo} />
            )
        }

        if (item.logo) {
            return (
                <span className={styles.logo}>
                    {item.logo}
                </span>
            )
        }

        return (<div />)
    }

    return (
        <>
            <div className={cn(styles.wrapper, className, {[styles.disabled]: disabled, [styles.error]: hasError })} onClick={handleClick}>
                {!selectedOption &&
                <div>{placeholder}</div>}

                {selectedOption &&
                <div className='flex gap-2 items-center'>
                    {renderLogo(selectedOption)}

                    <span className={styles.name}>
                        {selectedOption?.name}
                    </span>
                </div>}

                <FontAwesomeIcon icon={faAngleDown} />
            </div>

            <WithdrawalMethodOptionDrawer
                title={optionTitle}
                open={toShowDrawer}
                options={options}
                selectedIndex={selectedIndex}
                onClose={() => setToShowDrawer(false)}
                getContainer={widgetLayoutRef?.current}
                style={{
                    position: 'absolute'
                }}
                onChange={(method) => {
                    onChange(method.value)
                    setToShowDrawer(false)
                }}
            />
        </>
    )
})

WithdrawalMethodSelectInput.defaultProps = {
    placeholder: 'Select method'
}

export default inject('logger')(WithdrawalMethodSelectInput)
