import Modal from '@components/common/modal/Modal'

const showConnectionErrorMessage = () => {
    const options = {
        title: 'Connection Error',
        content: (
            <p>
                Please try again later
            </p>
        )
    }
    Modal.error(options)
}

export default showConnectionErrorMessage
