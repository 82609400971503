import React, { useState, useEffect } from 'react'
import cn from 'classnames'
import styles from './ContentWrapper.module.scss'

const ContentWrapper = (props) => {
    const { toShowBackgroundImage } = props

    return (
        <div className={styles.wrapper}>
            <div className={cn({ [styles.backgroundImage]: toShowBackgroundImage })} />
            {props.children}
        </div>
    )
}

ContentWrapper.defaultProps = {
}

export default ContentWrapper
