import React, { useState, useEffect } from 'react'
import cn from 'classnames'
import styles from './Header.module.scss'
import LogoButton from '@components/common/button/logo/LogoButton'

const Header = (props) => {
    useEffect(() => {
    }, [])

    return (
        <header className={cn(props.className, styles.wrapper)} style={props.style}>
            <LogoButton />
        </header>
    )
}

Header.defaultProps = {
    isCollapse: false
}

export default Header
