import React, { useEffect, useMemo, useState } from 'react'
import cn from 'classnames'
import SimpleBar from 'simplebar-react'
import Drawer from '@components/common/drawer/Drawer'
import styles from './ProviderSelectOptionDrawer.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import ProviderSelectOptionList from '../list/ProviderSelectOptionList'
import { HashLoader } from 'react-spinners'

const ProviderSelectOptionDrawer = (props) => {
    const { className, style, open, onClose, getContainer, title, options, onChange, loading, onScrollBottom } = props
    const scrollableNodeRef = React.createRef()
    const [providers, setProviders] = useState(options)
    const [selectedIndex, setSelectedIndex] = useState(props.selectedIndex)

    useEffect(() => {
        setProviders(options)
    }, [options])

    useEffect(() => {
        setSelectedIndex(props.selectedIndex)
    }, [props.selectedIndex])

    useEffect(() => {
        if (!scrollableNodeRef.current) return
        const containerElement = scrollableNodeRef.current
        const handleScroll = () => {
            if (
                containerElement.scrollTop + containerElement.clientHeight >=
                containerElement.scrollHeight
            ) {
                onScrollBottom()
            }
        }
    
        containerElement.addEventListener('scroll', handleScroll)
    
        return () => {
            containerElement.removeEventListener('scroll', handleScroll)
        }
    }, [scrollableNodeRef])

    const handleItemClick = (provider, index) => {
        setSelectedIndex(index)
        onChange(provider)
    }

    return (
        <Drawer
            className={cn(className, styles.wrapper)}
            open={open}
            onClose={onClose}
            getContainer={getContainer}
            footer={null}
            height={560}
            placement='bottom'
            style={style}
        >
            <div className={styles.header}>
                <div className={styles.title}>
                    {title}
                </div>

                <button type='button' className={styles.btnClose} onClick={onClose}>
                    <FontAwesomeIcon icon={faTimes} />
                </button>
            </div>

            <div className={styles.body}>
                <SimpleBar className={styles.providerListWrapper} forceVisible='y' autoHide={false}>
                    <ProviderSelectOptionList
                        data={providers}
                        selectedIndex={selectedIndex}
                        handleItemClick={handleItemClick}
                    />
                </SimpleBar>

                {loading &&
                <div className={styles.loaderWrapper}>
                    <HashLoader
                        size={20}
                        color='#36b3d0'
                    />
                </div>}
            </div>
        </Drawer>
    )
}

ProviderSelectOptionDrawer.defaultProps = {
    title: 'Choose OffRamp Provider',
    loading: false
}

export default ProviderSelectOptionDrawer
