import React from 'react'
import cn from 'classnames'
import styles from './TextInput.module.scss'
import { Input } from 'antd'

const TextInput = React.forwardRef((props, ref) => {
    return (
        <Input
            ref={ref}
            {...props}
            className={cn(props.className, styles.input)}
        />
    )
})

TextInput.defaultProps = {
}

export default TextInput
