import React from 'react'
import cn from 'classnames'
import styles from './Drawer.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose } from '@fortawesome/free-solid-svg-icons'
import { Drawer as AntdDrawer } from 'antd'

const Drawer = (props) => {
    return (
        <AntdDrawer
            closeIcon={
                <FontAwesomeIcon icon={faClose} />
            }
            {...props}
            className={cn(props.className, styles.wrapper)}
        />
    )
}

Drawer.defaultProps = {
}

export default Drawer
