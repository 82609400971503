import { useEffect, useMemo, useState } from 'react'
import cn from 'classnames'
import Drawer from '@components/common/drawer/Drawer'
import styles from './WidgetSidebar.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import { buyAssetPath, sellAssetPath } from '@constants/routes'

const WidgetSidebar = (props) => {
    const { className, style, open, onClose, getContainer, title } = props
    const links = useMemo(() => [
        {
            title: 'Buy Crypto',
            path: buyAssetPath
        },
        {
            title: 'Sell Crypto',
            path: sellAssetPath
        },
        {
            title: 'Terms & Conditions',
            path: '/'
        },
        {
            title: 'Privacy Policy',
            path: '/'
        }
    ], [])

    return (
        <Drawer
            className={cn(className, styles.wrapper)}
            open={open}
            onClose={onClose}
            getContainer={getContainer}
            width={300}
            footer={null}
            style={style}
        >
            <div className={styles.header}>
                <div className={styles.title}>
                    {title}
                </div>

                <button type='button' className={styles.btnClose} onClick={onClose}>
                    <FontAwesomeIcon icon={faTimes} />
                </button>
            </div>

            <div className={styles.body}>
                <nav>
                    <ul className={styles.linkList}>
                        {links.map((link, index) => (
                            <li key={index}>
                                <Link to={link.path} onClick={onClose}>
                                    {link.title}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </nav>
            </div>
        </Drawer>
    )
}

WidgetSidebar.defaultProps = {
    title: 'Menu'
}

export default WidgetSidebar
