const validateEndpointPath = '/onramp/validate'
const getSupportedCryptosEndpointPath = '/onramp/supported-cryptos'
const getSupportedFiatCurrenciesEndpointPath = '/onramp/supported-currencies'
const getBestExchangeRateEndpointPath = '/onramp/best-price'
const getProviderQuotDetailEndpointPath = (id) => `/onramp/partner/${id}`
const getProviderPaymentMethodsEndpointPath = (id) => `/onramp/partner/${id}/payment-methods`

const onrampService = (webClient) => ({
    async validate (body) {
        return await webClient.post(validateEndpointPath, body)
    },

    async getSupportedCryptos (params) {
        return await webClient.get(getSupportedCryptosEndpointPath, params)
    },

    async getSupportedFiatCurrencies (params) {
        return await webClient.get(getSupportedFiatCurrenciesEndpointPath, params)
    },

    async getBestExchangeRate (params) {
        return await webClient.get(getBestExchangeRateEndpointPath, params)
    },

    async getProviderQuoteDetail (id, params ) {
        return await webClient.get(getProviderQuotDetailEndpointPath(id), params)
    },

    async getProviderPaymentMethods (id, params = {}) {
        return await webClient.get(getProviderPaymentMethodsEndpointPath(id), params)
    }
})

export default onrampService
