import { homePath } from '@constants/routes'
import { Link } from 'react-router-dom'
import cn from 'classnames'
import styles from './LogoButton.module.scss'
import { inject } from 'mobx-react'

const LogoButton = (props) => {
    const { className, toShowAppName, config } = props
    const appName = config.app.name

    return (
        <Link to={homePath} className={cn(styles.logo, className)}>
            <img src='/images/logo/avenir.svg' />
            {toShowAppName && <span>{appName}</span>}
        </Link>
    )
}

LogoButton.defaultProps = {
    toShowAppName: true
}

export default inject('config')(LogoButton)
