import classNames from 'classnames'
import styles from './PaymentMethodOptionList.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faCreditCard } from '@fortawesome/free-solid-svg-icons'

const PaymentMethodOptionList = (props) => {
    const { className, data, selectedIndex, handleItemClick, loading } = props

    const renderLogo = (item) => {
        const isUrl = item.logo && item.logo.startsWith('http')
        if (isUrl) {
            return (
                <img className={styles.logo} src={item.logo} />
            )
        }

        if (item.logo) {
            return (
                <span className={styles.logo}>
                    {item.logo}
                </span>
            )
        }

        return (<div />)
    }

    return (
        <ul className={classNames(className, styles.wrapper)}>
            {data.map((item, index) => (
                <li key={index} onClick={() => handleItemClick(item, index)}>
                    {renderLogo(item)}

                    <div className={styles.name}>
                        {item.name}
                    </div>

                    <div className='ml-auto'>
                        {index === selectedIndex &&
                        <FontAwesomeIcon icon={faCheck} className='mr-2' />}
                    </div>
                </li>
            ))}

            {!loading && data.length === 0 &&
            <p className='text-center flex flex-col items-center jusityf-center gap-3'>
                <FontAwesomeIcon icon={faCreditCard} className='text-gray-400 text-[30px]' />
                No withdrawal method found
            </p>}
        </ul>
    )
}

export default PaymentMethodOptionList
