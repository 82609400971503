import { Routes, Route, Navigate } from 'react-router-dom'
import HomePage from '@pages/index'

const MainRoutes = (props) => {
    return (
        <Routes>
            <Route
                index
                element={
                    <HomePage />
                }
            />
        </Routes>
    )
}

export default MainRoutes
