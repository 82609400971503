import cn from 'classnames'
import WidgetLayout from '@components/widget/layout/WidgetLayout'
import styles from './SellAssetWidget.module.scss'
import SellAssetForm from '../form/SellAssetForm'
import { useRef, useState } from 'react'

const SellAssetWidget = (props) => {
    const { className, formProps } = props
    const widgetLayoutRef = useRef(null)

    return (
        <WidgetLayout
            ref={widgetLayoutRef}
            className={cn(className, styles.wrapper)}
            title='Sell Crypto'
        >
            <SellAssetForm
                widgetLayoutRef={widgetLayoutRef}
                defaultValues={formProps.defaultValues}
                onSuccessSubmit={formProps.onSuccessSubmit}
            />
        </WidgetLayout>
    )
}

export default SellAssetWidget
