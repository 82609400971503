import React from 'react'
import cn from 'classnames'
import styles from './Tag.module.scss'
import { Tag as AntdTag } from 'antd'

const Tag = (props, ref) => {
    return (
        <AntdTag
            {...props}
            className={cn(props.className, styles.tag, styles[props.type])}
        />
    )
}

Tag.defaultProps = {
}

export default Tag
