const validateEndpointPath = '/offramp/validate'
const getSupportedCryptosEndpointPath = '/offramp/supported-cryptos'
const getSupportedFiatCurrenciesEndpointPath = '/offramp/supported-currencies'
const getBestExchangeRateEndpointPath = '/offramp/best-price'
const getProviderQuotDetailEndpointPath = (id) => `/offramp/partner/${id}`
const getProviderWithdrawalMethodsEndpointPath = (id) => `/offramp/partner/${id}/payment-methods`

const offrampService = (webClient) => ({
    async validate (body) {
        return await webClient.post(validateEndpointPath, body)
    },

    async getSupportedCryptos (params) {
        return await webClient.get(getSupportedCryptosEndpointPath, params)
    },

    async getSupportedFiatCurrencies (params) {
        return await webClient.get(getSupportedFiatCurrenciesEndpointPath, params)
    },

    async getBestExchangeRate (params) {
        return await webClient.get(getBestExchangeRateEndpointPath, params)
    },

    async getProviderQuoteDetail (id, params ) {
        return await webClient.get(getProviderQuotDetailEndpointPath(id), params)
    },

    async getProviderWithdrawalMethods (id, params = {}) {
        return await webClient.get(getProviderWithdrawalMethodsEndpointPath(id), params)
    }
})

export default offrampService
