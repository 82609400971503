import classNames from 'classnames'
import styles from './AssetSelectOptionList.module.scss'
import Tag from '@components/common/tag/Tag'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faDollar } from '@fortawesome/free-solid-svg-icons'

const AssetSelectOptionList = (props) => {
    const { className, data, selectedIndex, handleItemClick, loading } = props

    return (
        <ul className={classNames(className, styles.wrapper)}>
            {data.map((item, index) => (
                <li key={index} onClick={() => handleItemClick(item, index)}>
                    {item.logoUrl &&
                    <img src={item.logoUrl} alt={item.symbol} width={30} height={30} />}

                    {!item.logoUrl && <div className={styles.imagePlaceholder} />}

                    <div>
                        <div className={styles.title}>
                            {item.name}
                        </div>

                        <div>
                            {item.desc}
                        </div>
                    </div>

                    <div className='ml-auto'>
                        {index === selectedIndex &&
                        <FontAwesomeIcon icon={faCheck} className='mr-2' />}

                        {item.isPopular &&
                        <Tag type='primary'>Popular</Tag>}
                    </div>
                </li>
            ))}

            {!loading && data.length === 0 &&
            <p className='text-center flex flex-col items-center jusityf-center gap-3'>
                <FontAwesomeIcon icon={faDollar} className='text-gray-400 text-[30px]' />
                No assets found
            </p>}
        </ul>
    )
}

export default AssetSelectOptionList
