import React, { useState, useEffect, ef } from 'react'
import cn from 'classnames'
import Header from './header/WidgetHeader'
import Sidebar from './sidebar/WidgetSidebar'
import ContentWrapper from './content-wrapper/WidgetContentWrapper'
import styles from './WidgetLayout.module.scss'
import { useMediaQuery } from 'react-responsive'

const WidgetLayout = React.forwardRef((props, ref) => {
    const { className, title, toShowHeader, contentWrapperClassName } = props
    const [toShowSidebar, setToShowSidebar] = useState(false)
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' })

    return (
        <div ref={ref} className={cn(styles.wrapper, className)}>
            {toShowHeader &&
            <Header title={title} toggleMenuButtonOnClick={() => setToShowSidebar(true)} />}

            <Sidebar
                open={toShowSidebar}
                onClose={() => setToShowSidebar(false)}
                getContainer={!isMobile ? ref.current : null}
                style={{
                    position: 'absolute'
                }}
            />

            <ContentWrapper className={contentWrapperClassName}>
                {props.children}
            </ContentWrapper>
        </div>
    )
})

WidgetLayout.defaultProps = {
    toShowHeader: true
}

export default WidgetLayout
