import React, { useState, useEffect } from 'react'
import { HashRouter, BrowserRouter, Routes, Route, useLocation, Navigate, useNavigate } from 'react-router-dom'
import { asReduxStore, connectReduxDevtools } from 'mst-middlewares'
import AppProvider from '@contexts/app/AppProvider'
import showServerErrorMessage from '@helpers/message/error/showServerErrorMessage'
import showTimeoutErrorMessage from '@helpers/message/error/showTimeoutErrorMessage'
import showConnectionErrorMessage from '@helpers/message/error/showConnectionErrorMessage'
import showNetworkErrorMessage from '@helpers/message/error/showNetworkErrorMessage'
import showCancelErrorMessage from '@helpers/message/error/showCancelErrorMessage'
import showUnknownErrorMessage from '@helpers/message/error/showUnknownErrorMessage'
import MainRoutes from 'routes/MainRoutes'

const App = (props) => {
    const webClient = props.container.webClient

    useEffect(() => {
        const load = async () => {
            const setErrorHandlers = (webClients) => {
                webClients.forEach((webClient) => {
                    webClient.setServerErrorHandler(showServerErrorMessage)
                    webClient.setTimeoutErrorHandler(showTimeoutErrorMessage)
                    webClient.setConnectionErrorHandler(showConnectionErrorMessage)
                    webClient.setNetworkErrorHandler(showNetworkErrorMessage)
                    webClient.setCancelErrorHandler(showCancelErrorMessage)
                    webClient.setUnknownErrorHandler(showUnknownErrorMessage)
                })
            }
            setErrorHandlers([webClient])
        }

        load()
    }, [])

    return (
        <AppProvider container={props.container}>
            <HashRouter>
                <MainRoutes />
            </HashRouter>
        </AppProvider>
    )
}

export default App
