import config from './config'
import WebClient from './utils/WebClient'
import MockWebClient from './utils/MockWebClient'
import logger from './utils/logger'
import offrampService from '@services/offrampService'
import onrampService from '@services/onrampService'

const generateAppContainer = async () => {
    // Logger
    logger.setLevel(logger[config.app.logLevel])

    // Web Clients
    let webClient = null
    const toRetry = config.app.toRetry
    const toMockAPIWithoutServer = (config.app.mockApi && !config.app.mockApiWithServer)
    if (toMockAPIWithoutServer) {
        const webClientOptions = {
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: 30000
        }
        if (!toMockAPIWithoutServer) webClientOptions.baseURL = config.app.apiUrl

        const mockModule = await import('api-mock/src/endpoints')
        const endpoints = mockModule.default
        webClient = new MockWebClient(webClientOptions, logger, toRetry, endpoints)
    } else {
        const webClientOptions = {
            baseURL: config.app.apiUrl,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            timeout: 30000
        }
        webClient = new WebClient(webClientOptions, logger, toRetry)
    }

    // Services
    const services = {}
    services.offrampService = offrampService(webClient)
    services.onrampService = onrampService(webClient)

    return { services, logger, config, webClient }
}

export default (async () => {
    const appContainer = await generateAppContainer()
    return appContainer
})()
