import React, { useEffect, useMemo, useRef, useState } from 'react'
import cn from 'classnames'
import styles from './AssetInput.module.scss'
import AssetSelectInput from './select/AssetSelectInput'

const AssetInput =  React.forwardRef((props, ref) => {
    const { className, label, widgetLayoutRef, amountInputProps, selectInputProps, hasError } = props
    const inputRef = useRef(null)
    const [focused, setFocused] = useState(false)

    const handleClick = (e) => {
        inputRef.current?.focus()
    }

    const handleAmountChange = (e) => {
        amountInputProps?.onChange(e.target.value)
    }

    const handleSelectChange = (asset) => {
        selectInputProps?.onChange(asset)
    }

    return (
        <div className={cn(styles.wrapper, className, { [styles.focused]: focused, [styles.error]: hasError })} onClick={handleClick}>
            <div className={styles.label}>
                {label}
            </div>

            <div className={styles.content}>
                <input
                    ref={inputRef}
                    type='number'
                    disabled={amountInputProps?.disabled}
                    value={amountInputProps?.value}
                    onFocus={() => setFocused(true)}
                    onBlur={() => setFocused(false)}
                    onChange={handleAmountChange}
                />

                <AssetSelectInput
                    onClick={(e) => e.stopPropagation()}
                    widgetLayoutRef={widgetLayoutRef}
                    optionTitle={selectInputProps?.optionTitle}
                    toAsyncLoad={selectInputProps?.toAsyncLoad}
                    toReturnOptionAsValue={selectInputProps?.toReturnOptionAsValue}
                    loadOptions={selectInputProps?.loadOptions}
                    options={selectInputProps?.options}
                    placeholder={selectInputProps?.placeholder}
                    value={selectInputProps?.value}
                    onChange={handleSelectChange}
                />
            </div>
        </div>
    )
})

AssetInput.defaultProps = {
    label: '',
    hasError: false
}

export default AssetInput
