import { create } from 'apisauce'
import qs from 'qs'
import MockAdapter from 'axios-mock-adapter'
import getPaths from '@helpers/mock/getEndpointPaths'

class MockHttpClientWrapper {
    httpClient = null
    authToken = null
    refreshToken = null
    checkToRetryHandler = null
    refreshTokenHandler = null
    refreshTokenExpiredHandler = null
    updateTokenHandler = null
    serverErrorHandler = null
    timeoutErrorHandler = null
    connectionErrorHandler = null
    networkErrorHandler = null
    cancelErrorHandler = null
    unknownErrorHandler = null
    logger = null
    toRetry = true
    mockAdapter = null
    mockEndpoints = null

    constructor (options, logger, toRetry = true, mockEndpoints = null) {
        this.logger = logger
        this.toRetry = toRetry
        this.httpClient = create(options)
        this.httpClient.addAsyncResponseTransform(this.asyncResponseTransform)
        this.mockAdapter = new MockAdapter(this.httpClient.axiosInstance)
        this.mockEndpoints = mockEndpoints
    }

    requestTransformFunc = async (request) => {
        if (this.authToken) {
            request.headers.Authorization = 'Bearer ' + this.authToken
        }

        if (request.method?.toLowerCase() === 'post' && request.headers['Content-Type'] === 'application/x-www-form-urlencoded') {
            request.data = qs.stringify(request.data)
        } else if (request.method?.toLowerCase() === 'put' && request.headers['Content-Type'] === 'application/x-www-form-urlencoded') {
            request.data = qs.stringify(request.data)
        }

        this.logger.debug('[Final Request]', request)

        return request
    }

    asyncResponseTransform = async (response) => {
        this.logger.debug('[Transform Response]', response)

        if (response.config.headers['Content-Type'] === 'application/json' && response.config.data) {
            response.config.data = JSON.parse(response.config.data)
        }

        let toRetry = this.toRetry
        if (this.toRetry && this.checkToRetryHandler) {
            toRetry = this.checkToRetryHandler(response)
        }

        const isRefreshTokenExpired = !response.ok && response.data?.error?.tokenStatus.includes('Refresh token is expired')
        const isRefreshTokenInvalid = !response.ok && response.data?.error?.tokenStatus.includes('Invalid refresh token')

        if (isRefreshTokenExpired && this.refreshTokenExpiredHandler) {
            this.refreshTokenExpiredHandler()
            return
        }

        if (response.problem === 'CLIENT_ERROR') {
            if (response.status === 401 &&
                this.refreshTokenHandler &&
                toRetry &&
                !isRefreshTokenInvalid &&
                !isRefreshTokenExpired
            ) {
                const refreshTokenRes = await this.refreshTokenHandler(this.refreshToken)

                this.mockAdapter.reset()

                if (refreshTokenRes.data.accessToken && refreshTokenRes.data.refreshToken) {
                    const { accessToken, refreshToken } = refreshTokenRes.data
                    if (this.updateTokenHandler) this.updateTokenHandler(accessToken, refreshToken)
                    this.authToken = accessToken
                    this.refreshToken = refreshToken

                    this.logger.debug('[Request Retry]', {
                        url: response.config.url,
                        data: response.config.data,
                        params: response.config.params
                    })

                    const retryRes = await this.any(response.config)
                    response.config = retryRes.config
                    response.data = retryRes.data
                    response.duration = retryRes.duration
                    response.headers = retryRes.headers
                    response.ok = retryRes.ok
                    response.originalError = retryRes.originalError
                    response.problem = retryRes.problem
                    response.status = retryRes.status
                }
            }
        } else if (response.problem === 'SERVER_ERROR') {
            if (this.serverErrorHandler) this.serverErrorHandler()
        } else if (response.problem === 'TIMEOUT_ERROR') {
            if (this.timeoutErrorHandler) this.timeoutErrorHandler()
        } else if (response.problem === 'CONNECTION_ERROR') {
            if (this.connectionErrorHandler) this.connectionErrorHandler()
        } else if (response.problem === 'NETWORK_ERROR') {
            if (this.networkErrorHandler) this.networkErrorHandler()
        } else if (response.problem === 'CANCEL_ERROR') {
            if (this.cancelErrorHandler) this.cancelErrorHandler()
        } else if (response.problem === 'UNKNOWN_PROBLEM') {
            if (this.unknownErrorHandler) this.unknownErrorHandler()
        }

        this.logger.debug('[Final Response]', response)
    }

    getMockResponse = async (handler, request = {}) => {
        if (typeof handler === 'function') {
            let resolver = null

            const p = new Promise((resolve) => {
                resolver = resolve
            })

            const req = {
                ...request,
                query: request.params,
                body: request.data
            }
            const response = {
                statusCode: 200,
                headers: {},
                body: {}
            }

            const res = {
                status: (statusCode) => {
                    response.statusCode = statusCode
                    return res
                },
                setHeader: (name, value) => {
                    response.headers[name] = value
                    return response
                },
                json: (result) => {
                    response.body = result
                    resolver(response)
                },
                send: (result) => {
                    response.body = result
                    resolver(response)
                }
            }

            handler(req, res)
            return await p
        } else {
            return {
                statusCode: 200,
                body: handler
            }
        }
    }

    mockEndpoint = async (request) => {
        const key = request.method + ' ' + request.url
        let handler = null

        await this.requestTransformFunc(request)

        if (!this.mockEndpoints[key]) {
            const requestPaths = getPaths(key)

            for (const [key, value] of Object.entries(this.mockEndpoints)) {
                const mockEndpointPaths = getPaths(key)
                if (requestPaths.length === mockEndpointPaths.length) {
                    for (let i = 0; i < mockEndpointPaths.length; i++) {
                        const mockEndpointPath = mockEndpointPaths[i]
                        const requestPath = requestPaths[i]
                        const isVariable = mockEndpointPath.startsWith(':')

                        if (mockEndpointPath !== requestPath && !isVariable) {
                            break
                        }

                        if (isVariable) {
                            const variableName = mockEndpointPath.substring(1)
                            if (!request.data) request.data = {}
                            request.data[variableName] = requestPath
                        }

                        if (i === mockEndpointPaths.length - 1) {
                            handler = this.mockEndpoints[key]
                        }
                    }
                }
            }
        } else {
            handler = this.mockEndpoints[key]
        }

        const mockResponse = await this.getMockResponse(handler, request)
        this.mockAdapter.onAny(request.url)
            .replyOnce(mockResponse.statusCode, mockResponse.body, mockResponse.headers)
    }

    post = async (...args) => {
        return new Promise((resolve) => {
            (async () => {
                const request = {
                    method: 'POST',
                    url: args[0],
                    headers: {},
                    data: args[1]
                }
                await this.mockEndpoint(request)

                const response = await this.httpClient.post(...args)
                resolve(response)
            })()
        })
    }

    put = async (...args) => {
        return new Promise((resolve) => {
            (async () => {
                const request = {
                    method: 'PUT',
                    url: args[0],
                    headers: {},
                    data: args[1]
                }
                await this.mockEndpoint(request)

                const response = await this.httpClient.put(...args)
                resolve(response)
            })()
        })
    }

    delete = async (...args) => {
        return new Promise((resolve) => {
            (async () => {
                const request = {
                    method: 'DELETE',
                    url: args[0],
                    headers: {},
                    params: args[1]
                }
                await this.mockEndpoint(request)

                const response = await this.httpClient.delete(...args)
                resolve(response)
            })()
        })
    }

    get = async (...args) => {
        return new Promise((resolve) => {
            (async () => {
                const request = {
                    method: 'GET',
                    url: args[0],
                    headers: {},
                    params: args[1]
                }
                await this.mockEndpoint(request)

                const response = await this.httpClient.get(...args)
                resolve(response)
            })()
        })
    }

    any = async (requestConfig) => {
        return new Promise((resolve) => {
            (async () => {
                const request = {
                    method: requestConfig.method,
                    url: requestConfig.url,
                    headers: requestConfig.headers,
                    data: requestConfig.data,
                    params: requestConfig.params
                }
                await this.mockEndpoint(request)

                const response = await this.httpClient.any(request)
                resolve(response)
            })()
        })
    }

    setAuthToken = (authToken) => {
        this.authToken = authToken
    }

    setRefreshToken = (refreshToken) => {
        this.refreshToken = refreshToken
    }

    setCheckToRetryHandler = (callback) => {
        this.checkToRetryHandler = callback
    }

    setRefreshTokenHanlder = (callback) => {
        this.refreshTokenHandler = callback
    }

    setRefreshTokenExpiredHandler = (callback) => {
        this.refreshTokenExpiredHandler = callback
    }

    setUpdateTokenHandler = (callback) => {
        this.updateTokenHandler = callback
    }

    setServerErrorHandler = (callback) => {
        this.serverErrorHandler = callback
    }

    setTimeoutErrorHandler = (callback) => {
        this.timeoutErrorHandler = callback
    }

    setConnectionErrorHandler = (callback) => {
        this.connectionErrorHandler = callback
    }

    setNetworkErrorHandler = (callback) => {
        this.networkErrorHandler = callback
    }

    setCancelErrorHandler = (callback) => {
        this.cancelErrorHandler = callback
    }

    setUnknownErrorHandler = (callback) => {
        this.unknownErrorHandler = callback
    }
}

export default MockHttpClientWrapper
